import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import ChainedBackend from "i18next-chained-backend";
import resourcesToBackend from "i18next-resources-to-backend";

  i18n  
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
      fallbackLng: "en",
      backend: {
        backends: [
          HttpBackend,
          resourcesToBackend((lng, ns) => import(`../translations/${lng}.json`))
        ],
        backendOptions: [{
          loadPath: `${process.env.REACT_APP_BACKEND}:${process.env.REACT_APP_BACKEND_PORT}/translations/{{lng}}`
        }]
      },
      detection: {
        order: ["path", "navigator", "localStorage"],
        lookupFromPathIndex: 0,
      },
      react: {
        useSuspense: true,
      },
    });

export default i18n;
