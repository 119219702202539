import styled from "styled-components";
import { breakpoint, darkGreen, lightGrey } from "../../constants";

export const Wrapper = styled.div`
  background-color: ${lightGrey};
  background-size: cover;
`;

export const Content = styled.div`
  padding: 50px 0;
  color: ${darkGreen};
`;

export const Heading = styled.div`
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  padding-bottom: 30px;
  @media (min-width: 768px) {
    font-size: 50px;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  div {
    flex-basis: 55%;
  }
  padding-bottom: 40px;
  @media (max-width: ${breakpoint}) {
    flex-direction: column;
    div {
      flex-basis: 100%;
    }
  }
`;

export const Image = styled.div`
  flex-basis: 45%;
  img {
    width: 100%;
  }
  @media (max-width: ${breakpoint}) {
    max-height: 300px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  padding-bottom: 20px;
  @media (max-width: ${breakpoint}) {
    font-size: 30px;
  }
`;

export const Subtitle = styled.div`
  font-size: 24px;
  font-style: italic;
  padding-bottom: 10px;
  @media (max-width: ${breakpoint}) {
    font-size: 18px;
  }
`;

export const Text = styled.div`
  font-size: 23px;
  @media (max-width: ${breakpoint}) {
    font-size: 18px;
  }
`;

export const List = styled.ul`
    padding-bottom: 20px;
`;

export const ListEl = styled.li`
  font-size: 23px;
  font-style: italic;
  @media (max-width: ${breakpoint}) {
    font-size: 18px;
  }
`

export const Link = styled.div`
  font-size: 23px;
  font-weight: 500;
  text-decoration: underline;
  font-weight: 600;

  a {
    color: ${darkGreen};
  }
  @media (max-width: ${breakpoint}) {
    font-size: 18px;
  }
`

export const EndingHeading = styled.div`
  font-size: 40px;
  font-weight: 600;
  text-align: center;
`

export const EndingSubheading = styled.div`
  font-size: 27px;
  text-align: center;
  padding-bottom: 30px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`