import styled from "styled-components";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import { breakpoint, darkGreen, topMargin, topMarginMobile } from "../../constants";
import { MdNavigateBefore } from "react-icons/md";
import { MdNavigateNext } from "react-icons/md";
import { ContentWrapper } from "../style";

const ContentWrapperMain = styled(ContentWrapper)`
  min-height: calc(100vh);
  padding-top: ${topMargin / 2}px;
  @media (max-width: ${breakpoint}) {
    min-height: calc(100vh);
    padding-top: ${topMarginMobile / 2}px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: relative;
  background-color: #3a494c;
  background-image: url("/assets/cta_background.jpg");
  background-size: cover;
`;

const Heading = styled.h1`
  font-size: 84px;
  line-height: 1;
  @media (max-width: ${breakpoint}) {
    font-size: 39px;
  }
`;

const Content = styled.div`
  color: white;
  padding: 40px 0;
  text-align: center;
`;

const Text = styled.div`
  font-size: 24px;
  max-width: 700px;
  margin: 0 auto;
  @media (max-width: ${breakpoint}) {
    font-size: 18px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
`

const Tabs = styled.div`
  display: flex;
  gap: 20px;
  padding: 0 20px 50px 20px;
  margin: 0 auto;
`;

const Tab = styled.div<{$isActive: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.$isActive ? darkGreen : "grey")};
  border-radius: 10px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  flex: 1;
  text-align: center;
  cursor: pointer;
  .after, .before {
    display: none;
  }
  div.tab {
    width: 100%;
    padding: 10px 0;
  }

  @media (max-width: ${breakpoint}) {
    display: ${(props) => (props.$isActive ? 'flex' : "none")};
    justify-content: space-between;

    .after, .before {
      display: flex;
      justify-content: center;
      padding: 0 10px;
      font-size: 20px;
    }
  }
`

const SectionMain = ({ activeTab, setActiveTab }: { activeTab: string; setActiveTab: (value: string) => void }) => {
  const { t } = useTranslation();

  const tabsConfig = [
    { key: 'sourcing', title: t('main.tabs.sourcing.tab-title') },
    { key: 'procurement', title: t('main.tabs.procurement.tab-title') },
    { key: 'supply-chain', title: t('main.tabs.supply-chain.tab-title') },
    { key: 'contract-management', title: t('main.tabs.contract-management.tab-title') },
  ];

  const activeIndex = tabsConfig.findIndex(tab => tab.key === activeTab)

  return (
    <Wrapper>
      <ContentWrapperMain>
        <Content>
          <Heading>{t("main.title")}</Heading>
          <Text>{t("main.description")}</Text>
          {/* <ButtonContainer>
            <Button $isWhite to="/book-a-demo">{t("main.request-consultation")}</Button>
          </ButtonContainer> */}
        </Content>
      </ContentWrapperMain>
      <Tabs>
        {tabsConfig.map(({ key, title }) => (
          <Tab
            key={key}
            $isActive={activeTab === key}
          >
            <div className="before" onClick={() => {
              setActiveTab(tabsConfig[activeIndex > 0 ? activeIndex - 1 : tabsConfig.length - 1].key)
            }}>
              <MdNavigateBefore />
            </div>
            <div className="tab" onClick={(e) => {
              e.stopPropagation()
              setActiveTab(key)
            }}>
              {title}
            </div>
            <div className="after" onClick={() => {
              setActiveTab(tabsConfig[activeIndex < 3 ? activeIndex + 1 : 0].key)
            }}>
              <MdNavigateNext />
            </div>
          </Tab>
        ))}
      </Tabs>
    </Wrapper>
  );
};

export default SectionMain;
