export const darkGreen = "#1C6434";
export const darkGreenHover = "#0c320c";
export const lightGrey = "#f7f7f7";
export const orange = "#d6b57d";

export const topMargin = 140;
export const topMarginMobile = 100;

export const breakpoint = '900px';

