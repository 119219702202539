import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakpoint, darkGreen, lightGrey } from "../../constants";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../../pages/style";
import Button from "../Button";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

const Wrapper = styled.div`
  width: 100%;
  background-color: ${lightGrey};
  color: ${darkGreen};
  padding: 20px 0;
  font-size: 13px;
`;

const FlexWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    padding-top: 20px;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 20px;
  }
`

const StyledLink = styled(Link)`
  color: ${darkGreen};
  text-decoration: none;
`;

const Logo = styled.div`
  width: 90px;
  margin-left: 40px;
  img {
    width: 100%;;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    margin: 0 auto;
  }
`;

const Links = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  @media (max-width: ${breakpoint}) {
    gap: 0px;
    flex-wrap: wrap;
    flex-direction: column;
  }
`

const Ending = styled.div`
  text-align: center;
`

const Block = styled.div`
  font-size: 16px;
  flex-basis: 25%;
`

const Heading = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
`

const LinkFooter = styled.div`
  padding-bottom: 10px;
`

const Divider = styled.div`
  @media (max-width: ${breakpoint}) {
    display: none;
  }
`

const Copy = styled.div`
  @media (max-width: ${breakpoint}) {
    padding-top: 10px;
  }
`
const Footer = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ContentWrapper>
        <FlexWrapper>
          <Block>
            <FaFacebookF size={30} style={{ marginRight: 20 }} />
            <FaInstagram size={30} style={{ marginRight: 20 }}/>
            <FaLinkedinIn size={30} />
          </Block>
          <Block>
              <Logo><img src="/assets/logo.png" alt="logo" /></Logo>
              <Button to="/book-a-demo">Book a demo</Button>
          </Block>
          
          {/* <Block>
            <Heading>New to Eco Connect?</Heading>
            <LinkFooter>About Us</LinkFooter>
            <LinkFooter>Why Eco Connect</LinkFooter>
            <LinkFooter>Explore Solutions</LinkFooter>
          </Block>
          <Block>
            <Heading>Helpful Links</Heading>
            <LinkFooter>Find a Partner</LinkFooter>
            <LinkFooter>Login Information</LinkFooter>
            <LinkFooter>Knowledge Center</LinkFooter>
          </Block> */}
        </FlexWrapper>
        <Ending>
          <Links>
            <StyledLink to="/terms-and-conditions">Terms and Conditions</StyledLink>
            <Divider>|</Divider> 
            <StyledLink to="/cookie-policy">Cookies Policy</StyledLink>
            <Divider>|</Divider> 
            <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
          </Links>
          <Copy>{t("footer.copyright")}</Copy>
        </Ending>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Footer;
